.message-con-bot {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-style: normal;
    line-height: 20px;
    margin-left: 5px;
    max-width: 300px;
    min-height: 28px;
    padding: 8px 14px 9px;
    width: 78%;
    font-family: 'customFont', sans-serif;
    font-weight: 300;
    line-height: 26px;
    background: transparent;
    color: black;
    text-align: left;
}

#child{
    text-align: left;
    width: 100%;
}

